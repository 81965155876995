import React from "react";
import { graphql } from "gatsby";
import NewsletterComponent from "components/Newsletter";

export default function Newsletter({ pageBuilder, className, pageContext }) {
  const {
    sectionNewsletterHeadline,
    sectionNewsletterHeadlineTag,
    sectionNewsletterText,
    sectionNewsletterTextBelow,
    sectionNewsletterPadding,
    sectionNewsletterTheme,
    sectionNewsletterImage,
    sectionNewsletterBulletImage,
    sectionNewsletterSegmentId,
    sectionNewsletterButtonText,
    sectionNewsletterLawText,
  } = pageBuilder;

  return (
    <NewsletterComponent
      headline={sectionNewsletterHeadline}
      headlineTag={sectionNewsletterHeadlineTag}
      text={sectionNewsletterText}
      textBelow={sectionNewsletterTextBelow}
      padding={sectionNewsletterPadding}
      theme={sectionNewsletterTheme}
      image={sectionNewsletterImage}
      bulletImage={sectionNewsletterBulletImage}
      segmentId={sectionNewsletterSegmentId}
      buttonText={sectionNewsletterButtonText}
      specialLawText={sectionNewsletterLawText}
      pageContext={pageContext}
    />
  );
}

export const query = graphql`
  fragment PageBuilderNewsletterQuery on Craft_PageBuilderNewsletter {
    id
    sectionNewsletterHeadline
    sectionNewsletterHeadlineTag
    sectionNewsletterPadding
    sectionNewsletterButtonText
    sectionNewsletterText {
      content
    }
    sectionNewsletterTextBelow {
      content
    }
    sectionNewsletterTheme
    sectionNewsletterBulletImage {
      ...CraftImageFluid
    }
    sectionNewsletterImage {
      ...CraftImageFluid
    }
    sectionNewsletterSegmentId
    sectionNewsletterLawText {
      content
    }
  }

  fragment GlobalLawTextQuery on Craft_GlobalsSet {
    newsletterLawText {
      newsletterLawText {
        content
      }
    }
  }
`;
